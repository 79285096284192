@use './variables' as v;

.overflow-hidden {
  overflow: hidden;
}

.top-0 {
  top: 0;
}

.right-0 {
  right: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.cb-margin-top-0 {
  margin-top: 0;
}

.cb-footer-reset {
  margin-bottom: -115px;
  padding-bottom: 230px;
}

.cb-no-wrap {
  white-space: nowrap;
}

.cb-radio-legend {
  position: relative;

  .cb-radio-legend-tooltip {
    position: absolute;
    right: -40px;
  }
}

// OVERRIDE
// global change to override apricot style for labels
// changes color from gray to black and increases the font-size from 14px to 16px
.cb-label {
  color: v.$cb-black !important;
  font-size: 1rem !important;
  font-weight: 500;
  line-height: 1.5rem !important;
}

table .cb-dropdown-toggle {
  height: auto !important;
}

// UX override
.cb-table-expandable {
  tr:has(+ [aria-hidden='false']) {
    td,
    th {
      border-bottom-color: transparent !important;
    }
  }
}

.cb-btn-pulse.cb-no-padding {
  .cb-btn-pulse-dots {
    top: 9px;
  }
}

.cb-int-phone .cb-int-phone-container .cb-flag-dropdown.cb-open {
  min-height: 100px;
  height: auto !important;
}
