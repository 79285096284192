.clep-org-nav {
  margin-top: 16px;
  padding-bottom: 12px;

  .cb-dropdown {
    > .cb-dropdown-toggle {
      padding: 0 !important;
      align-items: center !important;
      height: 32px !important;
    }
  }
}
